import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Link = makeShortcode("Link");
const Video = makeShortcode("Video");
const Process = makeShortcode("Process");
const AudioPlayer = makeShortcode("AudioPlayer");
const Aligner = makeShortcode("Aligner");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-freedom",
      "style": {
        "position": "relative"
      }
    }}>{`🦄 Freedom`}<a parentName="h1" {...{
        "href": "#-freedom",
        "aria-label": " freedom permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Before reading through this note, ask a few people you respect to define "freedom" and say what
it means to them. Like most fundamental concepts we've covered so far - trust, value, meaning,
money and speech - the answers to this basic inquiry are often shallow or insufficient.`}</p>
    <p><strong parentName="p">{`Freedom is the ability to be conscious of the constraints within which you live.`}</strong></p>
    <p>{`This definition via complementary opposites should be familiar by now. Buddhists call it "the
freedom of no escape". There is nowhere to be but here. Truly accepting this fact and submitting
to reality as it is, rather than how you wish it to be, is the beginning of liberation.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: To be free means to be conscious of what?`}</p>
        <p>{`The constraints within which you live.`}</p>
      </Card>
    </Flash>
    <p>{`The struggle in our day and age is not collective; it is individual. This is not to say there are no collective issues worth fighting for: basic needs (land, shelter, water, food, and sanitation), equal opportunity, and just institutions are all important. As `}<a parentName="p" {...{
        "href": "/learn/module-2/debt"
      }}>{`Graeber showed`}</a>{`: this is the same revolutionary program from antiquity: "Forgive debt and redistribute the land." However, what is required - `}<em parentName="p">{`in addition`}</em>{` - is "`}<a parentName="p" {...{
        "href": "/learn/module-0/conversation/#old-gifts-anew"
      }}>{`every single soul taking every single step`}</a>{`" so that we can figure out together how to create what was old community in future form.`}</p>
    <p>{`Freedom will not be found in some idealized future where we all enjoy sovereignty, universal suffrage, basic income, and any other transient goal cooked up by the current zeitgeist. Again, these projects are important - they build human capacity and ability - but they will not set us free.`}</p>
    <p>{`Freedom is the simple combination of awareness and acceptance. It is here and now, or not at all. We are limited, flawed beings: there is no escaping this simple fact of experience. It is not by denying our boundaries, but by embracing them, that real liberation can become a practical reality, rather than a concept to be realized once some external project has come to fruition.`}</p>
    <blockquote>
      <p parentName="blockquote">{`According to Illich, it is only the willing acceptance of limits — a sense of enoughness — that can stop monopolistic institutions from appropriating the totality of the Earth’s available resources, including our `}<a parentName="p" {...{
          "href": "/learn/module-4/self-enquiry"
        }}>{`identities`}</a>{`, in their constant quest for growth. - `}<a parentName="p" {...{
          "href": "https://reallifemag.com/syllabus-for-the-internet-labors-of-love/"
        }}>{`Labors of Love`}</a></p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: A life free from self-concern combines what two qualities?`}</p>
        <p>{`Awareness and acceptance.`}</p>
      </Card>
    </Flash>
    <h1 {...{
      "id": "application",
      "style": {
        "position": "relative"
      }
    }}>{`Application`}<a parentName="h1" {...{
        "href": "#application",
        "aria-label": "application permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`How does this apply to trust and value? Well, value is generated from `}<strong parentName="p">{`trust in clearly shared
truths`}</strong>{`, and modern trust is an emergent property of protocols that define and encode what it
means to cheat so that it may be prevented without human interpretation or enforcement. `}</p>
    <p><strong parentName="p">{`Freedom is our conscious ability to decide which shared truths to trust`}</strong>{` based on how well
defined and encoded the concept of "cheating" is that created those truths. Meaning, we have
the freedom to choose some of our boundaries. It is not possible, though, to live in this world without any boundaries at all. Which is why the `}<em parentName="p">{`practice of freedom`}</em>{` includes an acceptance that it is not possible to exist without limitation. `}</p>
    <p>{`Lived freedom is the conscious navigation of responsibility. You are free to the extent you
take responsibility for the consequences of your actions, because "`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=ZmgQW8okUeo"
      }}>{`freedom is not for the ego,
it is `}<em parentName="a">{`from`}</em>{` the ego`}</a>{`."`}</p>
    <p>{`This is a critical point: the products we create should not promote "sovereignty" -
that way lies false marketing campaigns and disappointment. Our products should be conscious of,
and communicate clearly, how they constrain the people who use them. In doing so, they create
the environment for people to become aware of the trade-offs they themselves are making, which
is the `}<Link to="https://www.oreilly.com/openbook/freedom/ch07.html" mdxType="Link">{`only kind of freedom`}</Link>{` we can trust to generate sustainable value.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Freedom fundamentally has to do with being what?`}</p>
        <p>{`Conscious.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: In particular, freedom is the conscious ability to decide what?`}</p>
        <p>{`Which shared truths to trust.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: This is just another way of saying that `}<em parentName="p">{`lived freedom`}</em>{` is the conscious naviagation of what?`}</p>
        <p>{`Our responsibility.`}</p>
      </Card>
    </Flash>
    <Video src="https://www.youtube-nocookie.com/embed/MgLbfaB9ytw" mdxType="Video" />
    <h1 {...{
      "id": "free-software",
      "style": {
        "position": "relative"
      }
    }}>{`Free Software`}<a parentName="h1" {...{
        "href": "#free-software",
        "aria-label": "free software permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`We are hinting here at two kinds of freedom: the freedom `}<strong parentName="p">{`from`}</strong>{` egotistic ways of being which do not place `}<a parentName="p" {...{
        "href": "/conversation/reciprocity"
      }}>{`grateful reciprocity`}</a>{` first, and the freedom `}<strong parentName="p">{`to`}</strong>{` be aware and conscious of our own constraints. However, how are we to understand and enact this practically, as builders of a better web?`}</p>
    <p>{`Perhaps the first step is the simple recognition that we are not alone...`}</p>
    <p>{`Too often in the history of Western thought has freedom been associated with an imaginary liberty, or the sovereignty of the individual. However, `}<a parentName="p" {...{
        "href": "/learn/module-metta/relating-kernel"
      }}>{`Kernel emphasises relationships`}</a>{`, not only because it is a more ethical and integral approach, but also because it more closely mirrors the deepest ideas we have about `}<em parentName="p">{`what reality is`}</em>{` (a web of interactions).`}</p>
    <p>{`We acknowledge the work done on `}<a parentName="p" {...{
        "href": "https://raw.githubusercontent.com/kernel-community/kernel-v2/fea5f320e6b96180af524e37a3bd832f1b58fdc8/content/en/learn/module-3/freedom.mdx"
      }}>{`free software`}</a>{` (please scroll to the bottom of that page to read what used to be in this section and follow the many useful links there). However, many attempts to promote the cause of freedom in software have had about them a specific flavour of individual exceptionalism: they are cast as a battle between honorable hackers and closed, greedy corporations. We are aware of this history: both the positive aspects of the "golden rule" of sharing and the negative aspects of its failure to foster diversity, responsibility, and accountable behaviour. There is no meaningful freedom without courtesy for and care of others.`}</p>
    <p>{`As such, we invite you to consider `}<a parentName="p" {...{
        "href": "https://getdweb.net/principles"
      }}>{`the DWeb Principles`}</a>{` as an example of what integral understandings of free software in our day and age may look like. As is stated in the manifesto’s preamble: `}</p>
    <blockquote>
      <p parentName="blockquote">{`"These principles define the values of a decentralized web based on enabling agency of all peoples. `}{`[...]`}{` These stand alongside other sets of principles that share or expand upon these values, in recognition that our efforts to build a more just and equitable world are `}<strong parentName="p">{`interdependent`}</strong>{`."`}</p>
    </blockquote>
    <p>{`The principles are:`}</p>
    <Process mdxType="Process">
      <p>{`Technology for Human Agency`}</p>
      <p>{`Distributed Benefits`}</p>
      <p>{`Mutual Respect`}</p>
      <p>{`Humanity `}</p>
      <p>{`Ecological Awareness`}</p>
    </Process>
    <p>{`We strongly encourage you to read the principles yourself in full. Here, we present only a few choice phrases which have informed our own thinking:`}</p>
    <blockquote>
      <p parentName="blockquote">{`We stand for technology that enables the primacy of people as beneficiaries of the technology.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`We urge coexistence and interoperability`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`We believe that multiple technical means will be more effective than a single technical solution to achieve ethical and people-centric outcomes.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`High concentration of organizational control is antithetical to the decentralized web.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`We stand for open and transparent organizational practices, motivations, and governance, in a manner that actively pursues equity, mutual trust, and respect.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`We encourage building with harm-reduction in mind, and support the adoption of mechanisms that mitigate the potential for abuse, and consideration of those ‘not at the table’`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`We value systems that work towards reducing energy consumption and device resource requirements, while increasing device lifespan by allowing repair, recycling, and recovery.`}</p>
    </blockquote>
    <p>{`We go to such lengths to emphasise interdependence and mutuality in a post about freedom because `}<strong parentName="p">{`freedom is a means`}</strong>{`; it is not an end. This is a point made best by `}<a parentName="p" {...{
        "href": "/conversation/hospitality/deep-response/#freeing-civilization"
      }}>{`Harold Davis III in response`}</a>{` to David Graeber and David Wengrow's book `}<em parentName="p">{`The Dawn of Everything`}</em>{`.`}</p>
    <p>{`Harold illustrates that our obsessive focus on freedom as an end in itself misses all the thinking done by indigenous cultures for whom freedom was a given, based on the way their societies were structured around mutual aid. `}</p>
    <p>{`Nowhere in this post have we yet asked, "What can be done with freedom?" Yet, the DWeb Principles are an answer to that question. They are not a manifesto about our rights as "free individuals"; they are a nuanced and collaborative description of the kinds of worlds we can create when interdependence, mutuality, respect and care are our guiding lights.`}</p>
    <p>{`Ultimately, the freedom to create with others is the greatest and strangest gift this whole life imparts to each one of us.`}</p>
    <h2 {...{
      "id": "listen",
      "style": {
        "position": "relative"
      }
    }}>{`Listen`}<a parentName="h2" {...{
        "href": "#listen",
        "aria-label": "listen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/M3E2+-+Freedom.mp3" mdxType="AudioPlayer" />
    <Aligner center mdxType="Aligner">
      <p><img parentName="p" {...{
          "src": "/images/mod3-img/freedom.jpg",
          "alt": "Don Cherry's free play"
        }}></img></p>
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      